import { useEffect } from 'react'

import { useRecoilValue, useSetRecoilState } from 'recoil'

import { navigate, routes } from '@redwoodjs/router'

import { backUrlState } from 'src/atoms/navigation.atom'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

const AfterLogin = ({ children }: { children?: React.ReactNode }) => {
  const loggedOutRoutes: string[] = [routes.home()]
  const backUrl = useRecoilValue(backUrlState)
  const { isAuthenticated } = useCurrentUser()
  const setBackUrl = useSetRecoilState(backUrlState)

  useEffect(() => {
    const redirectPath = backUrl.split('?')[0]
    if (isAuthenticated && backUrl && !loggedOutRoutes.includes(redirectPath)) {
      setBackUrl(routes.home())
      navigate(backUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return <>{children}</>
}

export default AfterLogin
