import { Box, useBreakpointValue } from '@chakra-ui/react'

import { navigate, routes } from '@redwoodjs/router'

import ReferralButton from 'src/components/widgets/buttons/ReferralButton/ReferralButton'
import { LOCAL_STORAGE_REFERRAL_KEY } from 'src/lib/constants'
import {
  PageSectionName,
  useAnalytics,
} from 'src/lib/hooks/analytics/useAnalytics'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'
import { useSetBackUrl } from 'src/lib/hooks/useSetBackUrl'
import { get } from 'src/lib/storedAffiliations'

function useGetCreatorButton(
  label: string,
  showSearch: boolean
): {
  show: boolean
  linkTo?: string
  label?: string | React.ReactNode
  variant?: string
  track?: (section: PageSectionName) => void
  Content?: React.ReactNode
} {
  const { isAuthenticated, creatorStatus, isAffiliate, isCreator } =
    useCurrentUser()
  const { track } = useAnalytics()
  const isXlPlus = useBreakpointValue({ sm: false, xl: true })
  const defaultLabel = label || 'Make An ItList'

  if (!isXlPlus && showSearch) {
    return {
      show: false,
    }
  }
  if (!isAuthenticated) {
    return {
      linkTo: routes.signin({ creator: true }),
      label: defaultLabel,
      show: true,
      track: (section: PageSectionName) => {
        track({
          eventName: 'Creator Sign Up Clicked',
          props: {
            buttonLabel: defaultLabel,
            section,
            referrerUserId: get(LOCAL_STORAGE_REFERRAL_KEY),
          },
        })
      },
    }
  }
  if (['suspended'].includes(creatorStatus)) {
    return {
      linkTo: routes.suspended(),
      label: <Box data-testid="headerButton-suspended">Account suspended</Box>,
      variant: 'secondaryGrayOutline',
      show: true,
    }
  }
  if (isCreator || isAffiliate) {
    return {
      show: true,
      Content: <ReferralButton hoverText="Get Paid" label="Invite Creators" />,
    }
  }
  return { show: false }
}

export default function useHeaderActions(
  label: string,
  section: PageSectionName,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  itListCreatorId?: number
) {
  const { id, isAuthenticated } = useCurrentUser()
  const isItListDetailsPage = !!itListCreatorId
  const showSearch =
    isItListDetailsPage && (!isAuthenticated || itListCreatorId !== id)
  const creatorButton = useGetCreatorButton(label, showSearch)
  const setBackUrl = useSetBackUrl()
  const clickHandler: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    navigate(creatorButton.linkTo)
    creatorButton.track?.(section)
    if (!isAuthenticated) setBackUrl()
    onClick?.(ev)
  }
  return { creatorButton, clickHandler, showSearch }
}
