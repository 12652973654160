import { ItListStatus } from 'types/graphql'

import { RegisterOptions } from '@redwoodjs/forms'

export const NAME_REGEXP = /^[a-zà-ú\s\-\.ãäæëõøœüç]{0,29}$/i
export const CHARS_SPACE_DASH_AMP_COMMA_APOS_REGEXP = /^[a-zà-ú\s\-&,\']+$/i
export const CHARS_NUM_SPACE_DASH_EMDASH_APOS_AMP_COMMA_REGEXP =
  /^[a-zà-ú0-9\-\s\u2013'&,]+$/i
export const SOCIAL_PROOF_REGEX =
  /(youtube\.com\/.*)|(youtu\.be\/.*)|(vimeo\.com\/[0-9]+.*)/g
export const URL_REGEX =
  /((?:(?:http?)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi

export const EMAIL_VALIDATION: RegisterOptions = {
  required: 'Please enter a valid email.',
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Please enter a valid email.',
  },
}
export const USERNAME_VALIDATION: RegisterOptions = {
  required: 'Pick a username. Help fans find you :)',
  pattern: {
    value: /^[a-z0-9_\-][a-z0-9\._\-]{0,29}$/i,
    message:
      'Only letters, numbers, dashes, underscore and period allowed, 30 characters maximum, cannot start with a period',
  },
}
export const FIRSTNAME_VALIDATION: RegisterOptions = {
  pattern: {
    value: NAME_REGEXP,
    message:
      'Only letters, dashes, dots and spaces allowed, 30 characters maximum, must start with a letter',
  },
}
export const FIRSTNAME_VALIDATION_REQUIRED: RegisterOptions = {
  required: 'Please enter your first name.',
  ...FIRSTNAME_VALIDATION,
}
export const FIRSTNAME_VALIDATION_OPTIONAL: RegisterOptions = {
  pattern: {
    value: NAME_REGEXP,
    message:
      'Only letters, dashes, dots and spaces allowed, 30 characters maximum, must start with a letter',
  },
}
export const LASTNAME_VALIDATION: RegisterOptions = {
  pattern: {
    value: NAME_REGEXP,
    message:
      'Only letters, dashes, dots and spaces allowed, 30 characters maximum, must start with a letter',
  },
}

export const LINK_URL_VALIDATION: RegisterOptions = {
  pattern: {
    value: URL_REGEX,
    message: 'Please enter a valid URL.',
  },
}

export const NETWORK_HANDLE_VALIDATION: RegisterOptions = {
  required: 'Please enter a valid handle',
  pattern: {
    value: /^[^\s@]{2,100}$/,
    message: 'Please enter a valid handle without @ signs or spaces',
  },
}

export const MIN_PASSWORD_LENGTH = 8
export const MAX_RECORDER_TIME_IN_SECONDS = 1000

export const NICHE_MIN_LENGTH = 3
export const NICHE_MAX_LENGTH = 60
export const BIO_MAX_LENGTH = 150
export const BIO_MAX_LENGTH_WITHOUT_WARNING = 130

export const PROFILE_PAGE_LG_WIDTH = 744
export const ITLIST_DETAILS_PAGE_LG_WIDTH = 551
export const ITLIST_FEATURES_PAGE_LG_WIDTH = 1077
export const PAGE_SIZE = 20

export const IT_LIST_COVER_PICTURE_WIDTH = 1500
export const IT_LIST_COVER_PICTURE_HEIGHT = 940

export const IT_LIST_DELETE_MESSAGE: Pick<
  Record<ItListStatus, Record<'title' | 'text', string>>,
  'published' | 'draft'
> = {
  draft: {
    title: 'Delete this draft?',
    text: "Are you sure? You can't undo this action.",
  },
  published: {
    title: 'Delete this live ItList?',
    text: "Are you sure? It will no longer be available on your profile, but people who've already purchased it will still have access to it.",
  },
}
export const VIMEO_URL = 'https://vimeo.com/818896410'

export const HELP_CENTER_URL =
  'https://itlist.notion.site/Help-Resources-a0a0b72062f643b19d52c57b7abb17e4'

export const GROW_YOUR_MAILING_LIST_URL =
  'https://itlist.notion.site/How-to-grow-your-mailing-list-with-ItLists-78fd662df3f14dd19fe3844fe5fc9366'

export const FEEDBACK_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdCz-xvXjxbHI66a2F-IKGGHNEsHoBtRIM2oU64A3L2oEFD3A/viewform?usp=pp_url&entry.1694871013=[email]&entry.198070672=[accountType]&entry.1754787932=[fullName]'

export const DEMO_PROFILE_NAME = 'itlist_hq'
export const IDEAS_IT_LIST_URL =
  'https://itlist.com/i/299/itlist-quick-start-guide?unlocked=true'
export const REGISTRATION_EMAIL_TEMPLATE_NAME = 'temporary-code'
export const FREE_IT_LIST_ACCESS_LINK_TEMPLATE_NAME = 'itlist-access-link'
export const SETTINGS_TIMEOUT_CHECK = 1000 * 60 // 1 hour
export const MAX_SM_INPUT = 30
export const MAX_SM_INPUT_NO_COUNTER = 25
export const MAX_LG_INPUT = 75
export const MAX_LG_INPUT_NO_COUNTER = 50
export const MAX_XL_INPUT = 500
export const MAX_XL_INPUT_NO_COUNTER = 450
export const MIN_LENGTH_USERNAME = 2
export const LOCAL_STORAGE_INSTRUCTIONS_KEY = 'install-app-banner'
export const LOCAL_STORAGE_VIEW_KEY = 'viewed-itLists'
export const LOCAL_STORAGE_VIEW_UNLOCK_KEY = 'viewed-unlock-itLists'
export const LOCAL_STORAGE_AFFILIATED_ITLIST_KEY = 'affiliated-itLists'
export const LOCAL_STORAGE_REFERRAL_KEY = 'referral'
export const LOCAL_STORAGE_FIRST_NAME_KEY = 'default-first-name'
export const LOCAL_STORAGE_LAST_NAME_KEY = 'default-last-name'
export const LOCAL_STORAGE_USERNAME_KEY = 'default-username'
export const REFERRER_VALIDITY_PERIOD_MONTHS = 12
export const BIO_LINK_MAX_LENGTH = 120
export const HIGHLIGHT_ANIMATION_DURATION_MS = 3000
export const AVAILABLE_COUNTRY_CODE_FOR_PAYOUTS = ['CA', 'US']
export const MAX_FILE_SIZE = 100000000 // 100 MB in bytes
export const PRICING_PAGE_URL = 'https://www.home.itlist.com/pricing'
