import { useEffect } from 'react'

import { navigate, routes, useLocation } from '@redwoodjs/router'

import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

const ForcedRedirect = ({ children }: { children?: React.ReactNode }) => {
  const { pathname } = useLocation()
  const { creatorStatus } = useCurrentUser()
  useEffect(() => {
    if (creatorStatus === 'verified' && !pathname.match(/^\/subscribe/)) {
      navigate(routes.subscribe())
    } else if (
      creatorStatus === 'onboarding' &&
      !pathname.match(/^\/onboarding/)
    ) {
      navigate(routes.onboarding())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorStatus])

  return <>{children}</>
}

export default ForcedRedirect
